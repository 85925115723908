$(function () {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });


    let audioId;

    let audio = document.createElement("audio");

    audio.addEventListener("ended", function () {
        $(".player").html('<svg class="icon icon-play"><use xlink:href="#icon-play"></use></svg>'), $(".player").css("--rotation", "45deg"), $(".player").css("--transition", "initial");
    });

    audio.addEventListener("loadeddata", function () {
        $("div[data-id='" + audioId + "']").find(".player").html('<svg class="icon icon-stop"><use xlink:href="#icon-stop"></use></svg>');
        $("div[data-id='" + audioId + "']").find(".player").css("--rotation", "315deg");
        $("div[data-id='" + audioId + "']").find(".player").css("--transition", "all " + Math.ceil(audio.duration) + "s");
    });

    $(document).on("click", ".ringtone .player,.ringtone-play .player", function () {
        if ($(this).find("svg").hasClass("icon-play")) {
            audioId = $(this).parent().attr("data-id");
            $.ajax({
                url: app.routes.musicInfo,
                dataType: "json",
                type: "POST",
                data: {
                    id: audioId,
                    is_play: true
                }
            }).done((resp) => {
                audio.setAttribute("src", resp.url);
                audio.currentTime = 0;
                $(".player").css("--rotation", "45deg");
                $(".player").css("--transition", "initial");
                $(".player").html('<svg class="icon icon-play"><use xlink:href="#icon-play"></use></svg>');
                $(this).html('<svg class="icon spin"><use xlink:href="#icon-spinner"></use></svg>');
                audio.play();
            })

        } else {
            $(".player").css("--rotation", "45deg");
            $(".player").css("--transition", "initial");
            $(".player").html('<svg class="icon icon-play"><use xlink:href="#icon-play"></use></svg>');
            audio.pause();
            audio.currentTime = 0;
        }
    });

    $(".downloadSingle").on("click", function (e) {
        e.preventDefault();
        const Id = $(this).val();
        $.ajax({
            url: app.routes.musicInfo,
            dataType: "json",
            type: "POST",
            data: {
                id: Id,
                is_download: true
            }
        }).done((resp) => {
            window.location.replace(resp.url)
        }).fail(console.log("Failed to started download"));

    });






    $(document).on("click", ".ringtone .buttons .download", function (e) {
        e.preventDefault();
        let url = $(this).parent().parent().find("a").attr("href");
        window.location.replace(url);
    });

    var sharetitle, shareUrl;

    $(document).on("click", ".ringtone .buttons .share", function (e) {
        e.preventDefault();
        let parent = $(this).parent().parent();
        shareUrl = parent.find("a").attr("href");
        sharetitle = parent.find(".title").text();
        actionShare();
    });


    $(document).on("click", ".ring-actions .share", function (e) {
        e.preventDefault();
        sharetitle = document.title;
        shareUrl = window.location.href;
        actionShare();
    });



    $(document).on("click", ".ringtone .buttons .fav", function (e) {
        e.preventDefault();
        let id = $(this).parent().parent().data("id");
        actionFav(e, id);
    });

    $(document).on("click", ".ring-actions .fav", function (e) {
        e.preventDefault();
        let id = $(this).parent().data("id");
        actionFav(e, id);
    });



    $(document).on("click", ".ring-actions .edit", function (e) {
        e.preventDefault();
        let id = $(this).parent().data("id");
        editRingtone(id);
    });

    $(document).on("click", ".ringtone .buttons .edit", function (e) {
        e.preventDefault();
        let id = $(this).parent().parent().data("id");
        editRingtone(id)

    });

    function editRingtone(id) {
        $.ajax({
            url: app.routes.musicInfo,
            type: 'post',
            dataType: "json",
            data: {
                id: id
            }
        }).done((res) => {
            $('#ringtone-edit-id').val(res.id);
            $('#ringtone-edit-title').val(res.title);
            $('#ringtone-edit-status').val(res.status);
            let option = new Option(res.category.name, res.category.id, true, true);
            $(".catlist").append(option).trigger('change');

            $.each(res.tags, function (k, v) {
                $(".tagsinput-edit").append(new Option(v.name, v.name, true, true)).trigger('change');
            });

            $("#ringtoneEdit").modal("show");
        }).fail(() => {
            console.log("failed to edit ringtone");
        });
    }

    $("#editRingtoneForm").on("submit", function (e) {
        e.preventDefault();
        let editformData = new FormData($(this)[0]);
        $.ajax({
            url: $("#editRingtoneForm").attr("action"),
            type: 'POST',
            processData: false,
            contentType: false,
            dataType: "json",
            data: editformData
        }).done((res) => {
            if (res.status == true) {
                window.location.replace(res.url);
            }
        }).fail(() => {
            console.log("failed to edit ringtone");
        });
    });

    $("#clipcopy").on("click", function (e) {
        e.preventDefault();
        if (navigator.clipboard !== undefined) {
            let copyText = $("#share-url");
            copyText.select();
            navigator.clipboard.writeText(copyText.val());
        } else {
            console.log("Clipboard not suppored");
        }
    });

    function actionFav(e, fid) {
        elem = $(e.currentTarget);
        $.ajax({
            url: window.app.routes.musicInfo,
            type: "post",
            dataType: "json",
            data: {
                id: fid,
                favourite: true
            }
        }).done((resp) => {
            console.log(resp.message);
            elem.toggleClass("light");
        }).fail(() => {
            console.log("Favourite faild :id " + fid);
        });
    }

    function actionShare() {
        if (navigator.share !== undefined) {
            //Web share API
            navigator
                .share({
                    title: "Share",
                    text: sharetitle,
                    url: shareUrl,
                })
                .then(function () {
                    console.info("Shared successfully.");
                })
                .catch(function (error) {
                    console.error("Error in sharing: ", error);
                });
        } else {
            $("#share-url").val(shareUrl);
            $("#modalDialog").modal("show");
        }
    }
    $(".shares").on("click", 'button,a', function (e) {
        e.preventDefault();
        let OUrl = $(this).data('href').replace("(title)", sharetitle).replace("(url)", shareUrl);
        window.open(OUrl);
    });




    $("#loader").on("click", function (e) {
        e.preventDefault();
        LoadData();
    });

    function LoadData() {
        ApiPaging(function (body) {
            $("#" + $("#pagination").data("id")).append(body);
        });
    }

    //Api Pagging
    var isLastPage = false;
    var isLoading = false;
    var loaderStyle = $("#loader").attr("data-type");
    function showloading(status) {
        if (status == true) {
            if (loaderStyle == "scroll") {
                isLoading = true;
                $("#loader").show();
            } else {
                $("#loader").prepend('<span id="loading" class="spinner-grow spinner-grow-sm" aria-hidden="true"></span> ');
                $("#loader").attr('disabled', 'disabled');
            }
        } else {
            if (loaderStyle == "scroll") {
                isLoading = false;
                $("#loader").hide();
            } else {
                $("#loader #loading").remove();
                $("#loader").removeAttr('disabled');
            }
        }
    }

    function ApiPaging(callback) {
        let nextPage = parseInt($('#pagination').attr("data-page")) + 1;
        let datahref = $("#pagination").attr("data-href");
        let reqUrl = datahref ? datahref : window.location.toString();
        let totalPage = parseInt($("#pagination").attr("data-pages"));
        $.ajax({
            url: reqUrl,
            type: "GET",
            dataType: "json",
            data: {
                page: nextPage
            },
            beforeSend: showloading(true)
        })
            .done(function (resp) {
                showloading(false);
                if (nextPage >= totalPage
                ) {
                    isLastPage = true;
                    $("#loader").remove();
                }
                $("#pagination").attr("data-page", nextPage);
                if (resp.status == true) {
                    startPagination();
                    callback(resp.data);
                }
            }).fail(function () {
                $("#loader").remove();
                console.log("API Fetch Error" + reqUrl);
            });
    }


    /**
     * if Pagination and 
     *  window and document width same
     */
    function startPagination() {
        if ($(document).height() == $(window).height()) {
            if (loaderStyle == "scroll") {
                if (!isLoading && !isLastPage) {
                    setTimeout(() => {
                        LoadData();
                    }, 400);
                }
            }
        }
    }

    /**
     * on scroll event
     */
    $(window).on("scroll", function () {
        if (loaderStyle == "scroll") {
            if ($(window).scrollTop() >= $(document).height() - $(window).height() - 100) {
                if (!isLoading && !isLastPage) {
                    LoadData();
                }
            }
        }
    });

    /**
     *  Start Select 2
     */

    if ($(".uploads-catlist").length) {
        $(".uploads-catlist").select2({
            dropdownParent: $('#ringtoneUpload'),
            theme: 'bootstrap-5',
            ajax: {
                url: $('.uploads-catlist').data("href"),
                dataType: "json",
                data: function (params) {
                    var query = {
                        q: params.term,
                    };
                    return query;
                },
            },
        });
    }

    if ($(".catlist").length) {
        $(".catlist").select2({
            dropdownParent: $('#ringtoneEdit'),
            theme: 'bootstrap-5',
            ajax: {
                url: $('.catlist').data("href"),
                dataType: "json",
                data: function (params) {
                    var query = {
                        q: params.term,
                    };
                    return query;
                },
            },
        });
    }

    if ($('.tagsinput-upload').length) {
        $('.tagsinput-upload').select2({
            ajax: {
                url: $('.tagsinput-upload').data("href"),
                dataType: "json",
                data: function (params) {
                    var query = {
                        q: params.term,
                    };
                    return query;
                },
            },
            dropdownParent: $('#ringtoneUpload'),
            theme: 'bootstrap-5',
            tags: true,
            placeholder: "Enter Tags then hit (,)",
            tokenSeparators: [',', ';', '\n'],
            createTag: function (params) {
                var term = $.trim(params.term);
                if (term === '') {
                    return null;
                }

                return {
                    id: term,
                    text: term,
                    newTag: true
                }
            }
        });
    }

    if ($('.tagsinput-edit').length) {
        $('.tagsinput-edit').select2({
            ajax: {
                url: $('.tagsinput-edit').data("href"),
                dataType: "json",
                data: function (params) {
                    var query = {
                        q: params.term,
                    };
                    return query;
                },
            },
            dropdownParent: $('#ringtoneEdit'),
            theme: 'bootstrap-5',
            tags: true,
            tokenSeparators: [',', ';', '\n'],
            placeholder: "Enter Tags then hit (,)",
            createTag: function (params) {
                var term = $.trim(params.term);
                if (term === '') {
                    return null;
                }

                return {
                    id: term,
                    text: term,
                    newTag: true
                }
            }
        });
    }
    /**End Select 2 */

    /**
     * if pagination avaiable and document width and window width same;
     */
    startPagination();


});